<div class="loginPage">
  <div class="loginInner">
    <div class="loginInnerHead">
      <img src="../../assets/images/siteLogo3.png" />
    </div>
    <p class="auth-status-info" [class.active]="loginForm.submitted && error">
      <!-- Неверный логин или пароль -->
      <!-- <app-error class="martop" [isAuth]="true" style="width: 100%"></app-error> -->
      <span class="error-block" *ngIf="loginForm.submitted && loginForm.invalid && error">
        {{ "login.LOGIN_FILL_ALL" | translate }}
      </span>
      <span class="error-block" *ngIf="loginForm.submitted && loginForm.valid && error">
        {{ "login.NOT_CORRECT_CREDENTIALS" | translate }}
      </span>
    </p>
    <form class="loginInnerBody" (ngSubmit)="onSubmit(loginForm)" #loginForm="ngForm">
      <div class="loginInnerBodyItem field">
        <input class="inputInner float login" placeholder="aaa" [type]="inputType" name="userName"
          [(ngModel)]="model.userName" id="login" #userName="ngModel"
          [ngClass]="{ error: !userName.valid && !userName.untouched }" required />
        <label for="login"> {{ 'login.LOGIN' | translate
          }}</label>
      </div>
      <div class="loginInnerBodyItem field">
        <input class="inputInner float login" type="password" name="password" id="password" placeholder="aaa"
          [(ngModel)]="model.password" #password="ngModel" [ngClass]="{ error: !password.valid && !password.untouched }"
          required />
        <label for="password">{{'login.PASSWORD' | translate}}</label>
      </div>
      <div class="loginInnerBodyButton">
        <button class="logButton" type="submit">
          {{ 'login.LOGIN' | translate }}
        </button>
      </div>
    </form>
    <div class="loginInnerLang" (click)="selectLang = !selectLang" [class.active]="selectLang"
      (clickOutside)="selectLang = false">
      <div class="loginInnerLangHead">
        <span class="loginInnerLangHeadText">
          {{ selectedLang }}
        </span>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z" fill="#212121" />
        </svg>

      </div>
      <div class="loginInnerLangBody">
        <div class="loginInnerLangBodyItem" *ngFor="let lang of languages" (click)="useLanguage(lang.lang, lang.name)">
          <span class="langItem">
            {{ lang.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
